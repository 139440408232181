import { socialLogin, socialSignup } from "@/social/index.js";

const apple = {
  init() {
    const state = process.env.VUE_APP_SOCIAL_APPLE_KEY;
    window.APPLE = AppleID.auth.init({
      clientId: "com.thegolf.wwwgw",

      scope: "email",
      redirectURI: "https://192.168.10.220:7011/login",
      state: state,
    });
  },

  async login(req) {
    console.log(req);
    if (!req) LoginFailure();
    const req_body = {
      id: req.userData.sub,
      name: false,
      email: req.userData.email,
      social: "apple",
    };
    await socialLogin(req_body);
  },

  async signup() {
    const req = await this.getInfo();
    await socialSignup(req);
  },

  async LoginFailure() {
    return false;
  },

  getInfo() {},
};

export default apple;
