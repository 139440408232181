import { socialLogin, socialSignup } from "@/social/index.js";

const Google = {
  makeReq(googleUser) {
    return new Promise((resolve, reject) => {
      const req = {
        name: googleUser.getBasicProfile().getName(),
        id: googleUser.getBasicProfile().getId(),
        email: googleUser.getBasicProfile().getEmail(),
        social: "google"
      };
      resolve(req);
    });
  },

  login(googleUser) {
    this.makeReq(googleUser).then(req => {
      socialLogin(req);
    });
  },

  signup(googleUser) {
    this.makeReq(googleUser).then(req => {
      socialSignup(req);
    });
  }
};

export default Google;
