import ax from "@/api/auth";
import store from "@/store";
import router from "@/router";
import i18n from "@/i18n";

function socialLogin(req, isSignup) {
  console.log("req", req);

  ax.socialLogin(req, (flag, msg) => {
    if (flag) {
      if (sessionStorage.getItem("is_initialize") == "1") {
        alert(i18n.t("alert.membership.need_edit_profile"));
        router.push({ name: "MyProfile" });
        return false;
      }
      router.push({ name: "MySummary" });
    } else {
      alert(msg);
    }
  });
  // const { data } = await store.dispatch("SOCIAL_LOGIN", req.id);
  // store.commit("setUserToken", data.data.token);
  // store.commit("setUser", data.data);

  // if (isSignup === "afterSignup")
  //   alert("회원가입 완료! 메인 페이지로 이동합니다.");

  // router.push("/main");
}

async function socialSignup(req) {
  try {
    await store.dispatch("VALID_ID", req.id);
    await store.dispatch("SIGNUP", req);

    socialLogin(req, "afterSignup");
  } catch (error) {
    console.log(error);

    const confirmYn = confirm(
      "이미 가입된 소셜 회원입니다. \n로그인 화면으로 이동하시겠습니까?"
    );
    if (confirmYn) router.push("/auth/login");
  }
}

export { socialLogin, socialSignup };
