<template>
  <div>
    <location />
    <div class="page mt-0 find_idpwd">
      <div class="wrap-1200">
        <div class="row g-5 align-items-md-stretch">
          <!-- Find ID -->
          <div class="col-lg-6">
            <div class="h-100 border">
              <b-form @submit.prevent="onSubmitFindId">
                <h4>{{ $t("title.main.id_find") }}</h4>
                <p>
                  {{ $t("text.main.id_find1") }}
                </p>
                <div class="form-floating mb-3">
                  <input
                    type="email"
                    name="email"
                    v-model="form.email"
                    class="form-control form-control-lg"
                    id="floatingInput"
                    placeholder=""
                  />
                  <label for="floatingInput">{{
                    $t("text.main.email_address")
                  }}</label>
                </div>
                <button type="submit" class="btn btn-primary btn-lg w-100">
                  {{ $t("button.common.confirm") }}
                </button>
              </b-form>
            </div>
          </div>
          <!-- // Find ID -->
          <!-- Find PWD -->
          <div class="col-lg-6">
            <div class="h-100 border">
              <b-form @submit.prevent="onSubmitFindPw">
                <h4>{{ $t("title.main.password_find") }}</h4>
                <p>
                  {{ $t("text.main.password_find1") }}
                </p>
                <div class="form-floating mb-3">
                  <input
                    type="id"
                    name="id"
                    v-model="form.id"
                    class="form-control form-control-lg"
                    id="floatingInput"
                    placeholder=""
                  />
                  <label for="floatingInput">{{ $t("title.common.id") }}</label>
                </div>
                <div class="form-floating mb-3">
                  <input
                    type="email"
                    name="email"
                    v-model="form.email"
                    class="form-control form-control-lg"
                    id="floatingInput"
                    placeholder=""
                  />
                  <label for="floatingInput">{{
                    $t("text.main.email_address")
                  }}</label>
                </div>
                <button type="submit" class="btn btn-primary btn-lg w-100">
                  {{ $t("button.common.confirm") }}
                </button>
              </b-form>
            </div>
          </div>
          <!-- // Find PWD -->
        </div>

        <p class="mt-4 text-subscript text-center text-lg-start fs-14">
          <span class="material-icons-outlined">info</span>
          {{ $t("text.main.id_password_find1") }}
          <a href="mailto:info@xgolf.com.au" class="text-decoration-underline more">{{ $t("text.main.xgolf_email") }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ax from "@/api/auth";
import _ from "lodash";
import location from "@/components/Location";

export default {
  name: "Find_Id_Pwd",
  components: {
    location,
  },
  data() {
    return {
      form: {
        email: "",
        id: "",
      },
    };
  },
  methods: {
    onSubmitFindPw() {
      if (!this.form.email) {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.common.email"),
          })
        );
        return false;
      }

      if (!this.form.id) {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.common.id"),
          })
        );
        return false;
      }
      ax.forgotPw(this.form, (flag, msg) => {
        if (flag) {
          alert(msg);
          this.$router.push("/login");
        } else {
          alert(msg);
        }
      });
    },
    onSubmitFindId() {
      if (!this.form.email) {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.common.email"),
          })
        );
        return false;
      }

      ax.forgotId(this.form, (flag, msg) => {
        if (flag) {
          alert(msg);
          // this.$router.push("/login");
        } else {
          alert(msg);
        }
      });
    },
  },
};
</script>